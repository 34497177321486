export default function () {

  [ ...document.querySelectorAll(".js-accordion-list")].forEach(el => {
    const links = [ ...el.querySelectorAll(".js-accordion-link")];

    links.forEach((link, i) => {
      link.addEventListener("keydown", (e) => {
        let index = i;

        switch(e.key) {
          case "Home":
            links[0].focus();
            break;
          case "End":
            links[links.length - 1].focus();
            break;
          case "Up":
          case "ArrowUp":
            if(i === 0){
              index = links.length - 1;
            } else {
              index--;
            }
            links[index].focus();
            break;
          case "Down":
          case "ArrowDown":
            if(i === links.length - 1){
              index = 0;
            } else {
              index++;
            }
            links[index].focus();
            break;
          default:
        }
      });
    });
  });
}
