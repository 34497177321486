export default function () {

  const initAccordion = (el, i) => {
    const link = el.querySelector(".js-accordion-link");
    const contentWrapper = el.querySelector(".js-accordion-content-wrapper");
    const content = el.querySelector(".js-accordion-content");
    const editorView = document.querySelector("body.experience-editor");

    let expanded = false;
    let contentBuffer = setTimeout(()=>{},1);

    if(el.classList.contains("is-open") || editorView) {
      expanded = true;
      toggleContent();
    }

    link.setAttribute("aria-expanded", expanded);
    link.setAttribute("id",`accordion-link-${i}`);
    link.setAttribute("aria-controls",`accordion-content-${i}`);

    contentWrapper.setAttribute("id",`accordion-content-${i}`);
    contentWrapper.setAttribute("aria-labelledby",`accordion-link-${i}`);

    link.addEventListener("click",(e) => {
      e.preventDefault();
      expanded = !expanded;
      toggleContent();
    });

    function toggleContent(expand) {
      const height = content.scrollHeight;

      link.setAttribute("aria-expanded", expanded);

      clearTimeout(contentBuffer);

      if(expanded) {
        el.classList.add("is-open");
        contentWrapper.setAttribute("style",`height:${height}px`);

        contentBuffer = setTimeout(()=> {
          contentWrapper.setAttribute("style","height: auto");
        }, 600);
      } else {
        el.classList.remove("is-open");
        contentWrapper.setAttribute("style",`height:${height}px`);

        setTimeout(()=> {
          contentWrapper.setAttribute("style","height: 0");
        }, 20);
      }
    }
  };

  document.querySelectorAll(".js-accordion").forEach(initAccordion);
}
