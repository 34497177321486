export default function () {

  const init = (el, i) => {
    const content = document.querySelector(".js-global-nav-content");
    const links = document.querySelectorAll(".ddk__global-header nav a");
    const controlButton = el.querySelector(".js-global-nav-button");
    const body = document.querySelector("body");
    const currentWidth = document.body.scrollWidth;
    const breakpointWidth = 970; // 971 is desktop

    controlButton.setAttribute("aria-expanded", false);
    controlButton.setAttribute("id",`global-nav-link-${ i }`);
    controlButton.setAttribute("aria-controls",`global-nav-content-${ i }`);

    content.setAttribute("id",`global-nav-content-${ i }`);
    content.setAttribute("aria-labelledby",`global-nav-link-${ i }`);

    controlButton.addEventListener("click",(e) => {
      e.preventDefault();

      if(el.classList.contains("is-open")){
        closeNavMenu();
      } else {
        openNavMenu();
      }
    });

    [ ...links ].forEach((link, index) => {
      link.addEventListener("keydown", (e) => {

        if (currentWidth > breakpointWidth) {
          return;
        }

        let target = index;

        switch(e.key) {
          case "Escape":
            controlButton.focus();
            closeNavMenu();
            break;
          case "Up":
          case "ArrowUp":
            e.preventDefault();
            if(index === 0) {
              target = links.length;
            }
            links[target - 1].focus();
            break;
          case "Down":
          case "ArrowDown":
            e.preventDefault();
            if(index === links.length - 1) {
              target = -1;
            }
            links[target + 1].focus();
            break;
          case "Tab":
            if(e.shiftKey) {
              if(index === 0) {
                e.preventDefault();
                closeNavMenu();
                controlButton.focus();
              }
            } else {
              if(index === links.length - 1) {
                e.preventDefault();
                closeNavMenu();
                controlButton.focus();
              }
            }
            break;
          default:
        }

      });
    });

    const openNavMenu = () => {
      el.classList.add("is-open");
      body.classList.add("is-open");
      controlButton.setAttribute("aria-expanded", true);
      setTimeout(function() {
        links[0].focus();
      }, 550);
    }

    const closeNavMenu = () => {
      el.classList.remove("is-open");
      body.classList.remove("is-open");
      controlButton.setAttribute("aria-expanded", false);
    }

  };

  [ ...document.querySelectorAll(".js-global-nav") ].forEach(init);

  [ ...document.querySelectorAll(".js-global-header .js-search-button") ].forEach(link => {
    link.addEventListener("click", () => {
      document.querySelector(".js-global-header").classList.toggle("is-searching");
    });
  })
}
