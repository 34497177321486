import AccordionList from './components/accordion-list.js';
import Accordion from './components/accordion.js';
import GlobalHeader from './components/global-header.js';
import FacetList from './components/facet-list.js';

AccordionList();
Accordion();
GlobalHeader();

setTimeout(() => {
  FacetList();
}, 500);

[...document.querySelectorAll('.ddk__rte')].forEach(el => {
  [...el.querySelectorAll('table')].forEach(table => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('ddk__table-wrapper');
    table.parentNode.insertBefore(wrapper, table);
    wrapper.appendChild(table);
  });
});
