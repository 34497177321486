export default function() {
  const el = document.querySelector('.js-faceted-search__interface');
  if (el) {
    const link = el.querySelector('.js-toggle-facet');
    const contentWrapper = el.querySelector(
      '.js-faceted-search__facet-container'
    );
    const content = el.querySelector('.js-faceted-search__filters');
    const toggleButton = document.querySelector('.js-toggle-facet');

    let contentBuffer = setTimeout(() => {}, 1);
    let isWideView = window.innerWidth > 820;

    toggleButton.addEventListener('click', e => {
      e.preventDefault();
      toggleFacet();
    });

    mobileView();
    window.addEventListener('resize', mobileView);

    const toggleFacet = () => {
      const height = content.scrollHeight;
      let expanded = link.getAttribute('aria-expanded');
      clearTimeout(contentBuffer);

      if (expanded === 'false') {
        el.classList.add('is-open');
        contentWrapper.setAttribute('style', `height:${height}px`);
        contentBuffer = setTimeout(() => {
          contentWrapper.setAttribute('style', 'height: auto');
        }, 600);
        link.setAttribute('aria-expanded', 'true');
      } else {
        el.classList.remove('is-open');
        contentWrapper.setAttribute('style', `height:${height}px`);
        link.setAttribute('aria-expanded', 'false');

        setTimeout(() => {
          contentWrapper.setAttribute('style', 'height: 0');
        }, 20);
      }
    };

    function mobileView() {
      const currentlyWide = window.innerWidth > 820;
      // if we've switched views from small to wide
      if (!isWideView && currentlyWide) {
        // remove the accordion code
        el.classList.remove('is-open');
        contentWrapper.removeAttribute('style');
        link.setAttribute('aria-expanded', 'false');
      }
      isWideView = currentlyWide;
    }
  }
}
